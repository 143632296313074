import React from "react";
import { connect } from "react-redux";
import CashFlowTable from "./CashFlowTable";
import AccountingForm from "./AccountingForm";
import { RecordsTable } from "../../../components/table";
import { CASH_FLOW_MODAL } from "../../../components/modals";
import { getViewMode } from "../../../utils";
import { selectCashFlow, setShouldSave, showModal, unsetShouldSave } from "../../../actions";
import { loadingSelector, shouldSaveSelector } from "../../../reducers";
import { isAdmin } from "../../../permissions";

const AccountingPage = ({ hasAdminPermission, ...props }) => {
  const accountingFormSubmitRef = React.useRef();
  const isViewMode = getViewMode();

  React.useEffect(() => {
    if (props.shouldSave) {
      setTimeout(() => {
        accountingFormSubmitRef.current.click();
      }, 500);
      setTimeout(() => {
        props.unsetShouldSave();
      }, 1000);
    }
  }, [props.shouldSave]);

  if (props.loading.error404) {
    return <></>;
  }
  return (
    <>
      <AccountingForm submitRef={accountingFormSubmitRef} />
      <RecordsTable
        title="Cash Flows"
        createButtonLabel={isViewMode ? "" : "Add Cash Flow"}
        buttonDisabled={!hasAdminPermission}
        onCreateClick={() => {
          props.selectCashFlow(null);
          props.showModal(CASH_FLOW_MODAL);
        }}
        Table={CashFlowTable}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    shouldSave: shouldSaveSelector(state),
    hasAdminPermission: isAdmin(state),
    loading: loadingSelector(state),
  };
};

export default connect(mapStateToProps, {
  selectCashFlow,
  showModal,
  setShouldSave,
  unsetShouldSave,
})(AccountingPage);
