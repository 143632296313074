import { CREATE_CASH_FLOW, DELETE_CASH_FLOW, FETCH_CASH_FLOW, LOADING } from "actions/types";
import advisorhq from "apis/advisorhq";
import * as types from "../types";
import { createTaskObject } from "./utils";
import * as notifications from "../../notifications";
import { hideModal } from "../modals";
import {
  DELETE_CASH_FLOW_MODAL,
  DELETE_FUNDER_AUCTION_ACTIVITY_MODAL,
  MANAGE_ENTITY_MODAL,
} from "../../components/modals";

export const fetchCashFlow = caseId => async (dispatch, getState) => {
  dispatch({ type: LOADING, payload: { cashFlow: true } });
  const response = await advisorhq.get(`/life_settlement/api/cases/v2/${caseId}/cash_flows/`);
  dispatch({ type: FETCH_CASH_FLOW, payload: response.data });
  dispatch({ type: LOADING, payload: { cashFlow: false } });
};

export const selectCashFlow = cashFlowId => async dispatch => {
  dispatch({ type: types.SELECT_CASH_FLOW, payload: cashFlowId });
};

export const createCashFlow = (caseId, values, row) => async (dispatch, getState) => {
  notifications.success("Saving...");
  const endpoint = `/life_settlement/api/cash_flow/`;
  const response = await advisorhq.post(endpoint, { ...values, case: caseId });
  dispatch({ type: types.CREATE_CASH_FLOW, payload: response.data });
};

export const editCashFlow = (caseId, cashFlowId, newValues, row) => async (dispatch, getState) => {
  notifications.success("Saving...");
  const endpoint = `/life_settlement/api/cash_flow/${cashFlowId}/`;
  const response = await advisorhq.patch(endpoint, { ...newValues, case: caseId });
  dispatch({ type: types.EDIT_CASH_FLOW, payload: response.data });
};

export const deleteCashFlow = (id, caseId) => async (dispatch, getState) => {
  try {
    await advisorhq.delete(`/life_settlement/api/cash_flow/${id}/`);
    notifications.success("CashFlow deleted successfully");
  } catch (e) {
    notifications.error(e.response.data.error);
  }
  dispatch({ type: DELETE_CASH_FLOW, payload: { id } });
  dispatch(hideModal(DELETE_CASH_FLOW_MODAL));

  // re-fetch
  dispatch({ type: types.LOADING, payload: { cashFlow: true } });
  const response = await advisorhq.get(`/life_settlement/api/cases/v2/${caseId}/cash_flows/`);
  let { results } = response.data;
  dispatch({ type: types.FETCH_CASH_FLOW, payload: results });
  dispatch({ type: types.LOADING, payload: { cashFlow: false } });
};
