import { CREATE_CASH_FLOW, DELETE_CASH_FLOW, EDIT_CASH_FLOW, FETCH_CASH_FLOW, SELECT_CASH_FLOW } from "actions/types";

export const defaultState = {
  cashFlow: [],
  activeCashFlowId: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_CASH_FLOW:
      return { ...state, cashFlow: action.payload };
    case SELECT_CASH_FLOW:
      return { ...state, activeCashFlowId: action.payload };
    case CREATE_CASH_FLOW: {
      return {
        ...state,
        activeCashFlowId: action.payload.id,
        cashFlow: [...state.cashFlow, action.payload],
      };
    }
    case EDIT_CASH_FLOW: {
      return {
        ...state,
        activeCashFlowId: action.payload.id,
        cashFlow: [...state.cashFlow.filter(cf => cf.id !== action.payload.id), action.payload],
      };
    }
    case DELETE_CASH_FLOW:
      delete state.cashFlow[action.payload.id];
      return state;
    default:
      return state;
  }
};

// Selectors
export const cashFlowSelector = state => state.cashFlow;

export const activeCashFlowSelector = ({ cashFlow, activeCashFlowId }) => {
  if (activeCashFlowId) {
    return cashFlow.find(cf => cf.id === activeCashFlowId);
  }
};
