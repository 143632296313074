import React from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";

import {
  activeCaseSelector,
  numberOfInsuredSelector,
  numberOfBeneficiariesSelector,
  numberOfPendingCaseFilesSelector,
  auctionAccessEnabledSelector,
  numberOfOwnersSelector,
  numberOfTasksSelector,
} from "reducers";
import { setShouldSave, pricingSetup, fetchLifeSettlementCase } from "actions";
import SendCasePDFButton from "./SendCasePDFButton";
import { Tab, TabGroup } from "../../../components/Tabs";
import { URLS } from "../../../constants";
import { getViewMode } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { isAdmin, isReadMode } from "permissions";
import { ButtonSaveChanges } from "../../../components/buttons";

const SubNavbar = ({
  caseInfo,
  insuredInfo,
  lifeExpectancyInfo,
  isActive,
  case_title,
  pricingSetup,
  auctionNotesFormSubmitRef,
  caseOverviewFormSubmitRef,
  caseTradingSummaryFormSubmitRef,
  closingDetailFormSubmitRef,
  hasAdminPermission,
  ...props
}) => {
  const history = useNavigate();

  if (!caseInfo) {
    return null;
  }

  const isViewMode = getViewMode();

  const BackToCasesButton = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center", margin: 0 }}>
        <button className="btn btn--secondary navbar-save-button" onClick={() => history(URLS.CASES)}>
          <FaArrowLeft /> Back to Cases
        </button>
      </div>
    );
  };

  const h3style = {
    width: "100%",
    top: "7.2rem",
    left: 0,
    zIndex: 1,
  };

  const onClickCallback = () => {
    // Re-fetch the case on every click on the Tabs
    props.fetchLifeSettlementCase(caseInfo.id);
  };

  const tabs = [
    { link: `${props.managementPath}/policy/`, label: "Policy" },
    { link: `${props.managementPath}/insured/`, label: `Insured (${props.insuredCount})` },
    { link: `${props.managementPath}/owners/`, label: `Owners (${props.ownersCount})` },
    { link: `${props.managementPath}/beneficiaries/`, label: `Beneficiaries (${props.beneficiariesCount})` },
  ];
  if (hasAdminPermission && props.auctionAccessEnabled) {
    tabs.push({ link: `${props.managementPath}/trading/`, label: "Trading" });
  }
  tabs.push({ link: `${props.managementPath}/accounting/`, label: "Accounting" });
  if (hasAdminPermission) {
    tabs.push({ link: `${props.managementPath}/closing/`, label: "Closing" });
  }
  tabs.push(
    { link: `${props.managementPath}/files/`, label: `Files (${props.pendingCaseFilesCount})` },
    { link: `${props.managementPath}/tasks/`, label: `Tasks (${props.pendingTasksFilesCount})` },
  );
  if (hasAdminPermission) {
    tabs.push({ link: `${props.managementPath}/activity/`, label: `Activity Log` });
  }

  return (
    <div
      style={{
        position: "fixed",
        top: "4rem",
        left: "0rem",
        width: "100%",
        zIndex: 25,
      }}
    >
      <Card style={{ border: "1px solid black", borderRadius: "0px" }}>
        <Card.Header
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            backgroundColor: "black",
          }}
        >
          <h5
            className="section-title navbar-title"
            style={{
              color: "white",
              alignItems: "center",
              marginBottom: 0,
              lineHeight: 2,
            }}
          >
            Case: {caseInfo.case_title}
          </h5>
          <div style={{ display: "flex" }}>
            <BackToCasesButton />
            {!props.readMode && <SendCasePDFButton />}
            <ButtonSaveChanges saveFn={props.saveFn} />
          </div>
        </Card.Header>
      </Card>
      <h3 className={"header tertiary"} style={h3style}>
        <TabGroup
          style={{
            width: "100%",
          }}
        >
          {tabs.map((tab, index) => {
            return (
              <Tab key={index} link={tab.link} onClickCallback={onClickCallback}>
                {tab.label}
              </Tab>
            );
          })}
        </TabGroup>
      </h3>
    </div>
  );
};

const mapStateToProps = state => {
  const hasAdminPermission = isAdmin(state);

  return {
    caseInfo: activeCaseSelector(state),
    insuredCount: numberOfInsuredSelector(state) || 0,
    ownersCount: numberOfOwnersSelector(state) || 0,
    beneficiariesCount: numberOfBeneficiariesSelector(state) || 0,
    pendingCaseFilesCount: numberOfPendingCaseFilesSelector(state) || 0,
    pendingTasksFilesCount: numberOfTasksSelector(state) || 0,
    auctionAccessEnabled: auctionAccessEnabledSelector(state),
    hasAdminPermission,
    readMode: isReadMode(state),
  };
};

export default connect(mapStateToProps, {
  pricingSetup,
  setShouldSave,
  fetchLifeSettlementCase,
})(SubNavbar);
