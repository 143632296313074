import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { CASH_FLOW_MODAL } from "components/modals";
import CashFlowForm from "./CashFlowForm";
import { activeCashFlowSelector } from "../../../reducers";

const CashFlowModal = ({ activeCashFlow }) => {
  return (
    <FullPageModal modalType={CASH_FLOW_MODAL}>
      <CashFlowForm record={activeCashFlow} />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    activeCashFlow: activeCashFlowSelector(state),
  };
};

export default connect(mapStateToProps, {})(CashFlowModal);
