import React from "react";
import { Form, Col, InputGroup } from "react-bootstrap";
import { useField, useFormikContext } from "formik";

import { isInvalid } from "./validation";
import Label from "./Label";
import FieldErrors from "./FieldErrors";
import FieldHelpText from "./FieldHelpText";
import { getViewMode } from "../../utils";
import IconLink from "../IconLink";
import { FaQuestionCircle } from "react-icons/all";

const TextInput = ({
  label,
  md = "",
  helpText,
  isRequired = false,
  tooltip = false,
  lowercase = false,
  inline = true,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const isViewMode = getViewMode();
  const isDisabled = props.disabled || isViewMode;

  if (!props.autoComplete) {
    props.autoComplete = "off";
  }

  const handleChange = e => {
    const value = e.target.value;
    setFieldValue(field.name, value);
  };

  const handleLowercaseChange = e => {
    let value = e.target.value;
    value = value.toLowerCase();
    setFieldValue(field.name, value);
  };

  let onChange;
  if (props.onChange) {
    onChange = props.onChange;
  } else {
    onChange = lowercase ? handleLowercaseChange : handleChange;
  }

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`} style={props.customStyle}>
      {label && !inline ? <Label label={label} tooltip={tooltip} required={isRequired} /> : null}
      <InputGroup>
        {label && inline ? (
          <InputGroup.Prepend>
            <InputGroup.Text>
              {label}
              {tooltip && (
                <IconLink
                  Icon={FaQuestionCircle}
                  iconConfig={{
                    className: "table--action-icon-tooltip",
                    style: { marginLeft: 5, marginRight: -5, marginBottom: 2 },
                  }}
                  tooltip={tooltip}
                />
              )}
            </InputGroup.Text>
          </InputGroup.Prepend>
        ) : null}
        {props.prepend && (
          <InputGroup.Prepend>
            <InputGroup.Text>{props.prepend}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <Form.Control
          {...field}
          {...props}
          disabled={isDisabled}
          onChange={onChange}
          isInvalid={isInvalid(meta)}
          style={props.grayout}
        />
        {props.append && (
          <InputGroup.Append>
            <InputGroup.Text>{props.append}</InputGroup.Text>
          </InputGroup.Append>
        )}
        <FieldErrors meta={meta} />
      </InputGroup>
      {helpText && <FieldHelpText>{helpText}</FieldHelpText>}
    </Form.Group>
  );
};

export default TextInput;
