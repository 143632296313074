import React from "react";
import { connect } from "react-redux";

import { showModal, fetchCashFlow, selectCashFlow } from "actions";
import { activeCaseSelector, cashFlowSelector, loadingCashFlowSelector } from "reducers";
import Table, { choiceCell, leftJustifiedCurrencyCell, datetimeCell, notesCell, sortDateCell } from "components/table";
import { cashFlowDisplayTypesChoices, cashFlowEntitiesChoices, cashFlowStatusChoices } from "./constants";
import { FaEdit, FaTrash } from "react-icons/fa";
import { CASH_FLOW_MODAL, DELETE_CASH_FLOW_MODAL } from "../../../components/modals";
import IconLink from "../../../components/IconLink";
import { isAdmin } from "../../../permissions";

const CashFlowTable = ({ hasAdminPermission, ...props }) => {
  React.useEffect(() => {
    if (props.caseInfo.submission_id) {
      document.title = `LifeRoc | ${props.caseInfo.submission_id} | Accounting`;
    }
    if (props.caseInfo.id) {
      props.fetchCashFlow(props.caseInfo.id);
    }
  }, [props.caseInfo.id]);

  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const cashFlowId = row.original.id;
        const caseId = row.original.case;
        if (row.original.type === "Total") return <></>;
        return (
          <>
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                props.selectCashFlow(row.original.id);
                props.showModal(CASH_FLOW_MODAL, {
                  id: cashFlowId,
                });
              }}
            />
            {hasAdminPermission && (
              <IconLink
                Icon={FaTrash}
                iconConfig={{ className: "table--action-icon btn--red" }}
                tooltip="Delete"
                onClick={() => {
                  props.showModal(DELETE_CASH_FLOW_MODAL, {
                    id: cashFlowId,
                    caseId,
                  });
                }}
              />
            )}
          </>
        );
      },
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: choiceCell(cashFlowDisplayTypesChoices),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: choiceCell(cashFlowStatusChoices),
    },
    {
      Header: "Entity",
      accessor: "entity",
      Cell: choiceCell(cashFlowEntitiesChoices),
    },
    {
      Header: "Date",
      accessor: "date",
      sortType: sortDateCell,
      Cell: datetimeCell,
    },
    {
      Header: "Last Modified",
      accessor: "last_edit_date",
      Cell: datetimeCell,
    },
    {
      Header: "Last Modified By",
      accessor: "last_edited_by_name",
    },
    {
      Header: "Notes",
      accessor: "notes",
      Cell: notesCell,
    },
    {
      Header: "Amount",
      accessor: "amount_display",
      className: "wrap-text",
      Cell: leftJustifiedCurrencyCell,
    },
  ];

  return (
    <Table
      columns={columns}
      data={props.cashFlow}
      defaultSort={true}
      paginated={false}
      emptyMessage={props.loading ? "Loading..." : "No records found"}
      sortBy={[{ id: "date", desc: true }]}
    />
  );
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
    cashFlow: cashFlowSelector(state) || [],
    loading: loadingCashFlowSelector(state),
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  fetchCashFlow,
  selectCashFlow,
  showModal,
})(CashFlowTable);
